@import "variable";
iframe {
  vertical-align: bottom;
}
.customization-wrapper {
  .color-picker {
    width: 100%;
  }

  .chrome-picker.color-picker-panel {
    position: absolute !important;
  }
}

.color-picker {
  width: 300px;

  .color-picker-display {
    display: flex;
    justify-content: space-between;
    border: solid 1px $gray-medium;
    border-radius: 4px;
    padding: 8px 8px 8px 16px;
    align-items: center;

    .color-value {
      margin: auto;
      margin-left: 0;
      text-align: center;
    }

    .color-window {
      width: 24px;
      height: 24px;
      border: solid 1px $gray-medium;
    }

    .color-picker-panel {
      position: relative;
      margin-left: 36px;
      z-index: 999;
    }
  }
}

.color-theme {
  display: flex;
  flex-direction: row;
  border: solid 1px $gray-medium;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover,
  &.active,
  &.selected {
    box-shadow: inset 0px 0px 0px 1px #008ecf;
    border-color: $blue-bright;
  }

  .color-window {
    width: 24px;
    height: 24px;
    border: solid 1px $gray-medium;
    margin: 3px;
  }
}

*,
:before,
:after {
  box-sizing: border-box;
}

body,
html {
  height: 100%;
}

html * {
  font-family: $font-stack;
}

body {
  margin: 0;
  padding: 0;
  background-color: $gray-lightest;
  line-height: 1.5;
  color: $body-text-color;
}

#root {
  min-height: 100%;
  position: relative;
}

p {
  margin-bottom: 22px;
}

img {
  vertical-align: middle;
}

// links
a {
  cursor: pointer;
  color: $blue-bright;
  text-decoration: none;
}

a:link,
a:visited {
  color: $blue-bright;
}

a:active,
a:hover {
  text-decoration: underline;
}

a:focus {
  outline: 0;
}

ul {
  padding: 0;
}

// header
header {
  background-color: $black;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    display: block;
  }
}

// main
main {
  margin: 24px auto;
  padding-bottom: 104px;

  & > section {
    & > div {
      background-color: $white;
      //min-height: 450px;
      min-height: calc(100vh - 192px);
      position: relative;
    }
  }
}

// container
.container {
  max-width: 1200px;
  margin-inline: auto;
}

// landing page
.landing-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 24px 0;

  h1 {
    font-size: 40px;
    font-weight: 400;
    color: $gray-darkest;
    margin: 0;

    span {
      font-weight: 700;
      color: $blue-bright;
    }
  }

  p {
    max-width: 700px;
    margin-inline: auto;
    font-size: 18px;
    text-align: center;
    line-height: 1.5;
    margin-top: 4px;
  }

  form {
    width: 100%;
    margin-top: 26px;

    .form-control {
      display: flex;
      gap: 16px;
      max-width: 768px;
      margin-inline: auto;

      input[type="url"] {
        width: 100%;
        height: 56px;
      }
    }
  }
}

//form
.form-control {
  label {
    font-size: 0.938rem;
  }

  input {
    &[type="url"],
    &[type="text"],
    &[type="email"],
    &[type="password"] {
      border: 1px solid $gray-medium;
      border-radius: 5px;
      text-align: left;
      padding: 0 15px;
      box-sizing: border-box;
      height: 40px;
      font-size: 0.85rem;
      display: block;
      width: 100%;
      font-weight: 400;
      line-height: 1.5;
      color: $gray-darkest;
      background-color: $white;
      background-clip: padding-box;
      -webkit-transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
      transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;

      &:focus {
        background-color: $white;
        border-color: #80bdff;
        outline: 0;
        box-shadow:
          0 0 10px $blue-bright,
          0 0 4px $blue-bright;
      }
    }

    &.large {
      font-size: 16px;
    }
  }
}

// button
.btn {
  box-shadow: none;

  &-primary,
  &-secondary,
  &-ghost {
    width: fit-content;
    outline: none;
    border: 0;
    padding: 0 18px;
    height: 36px;
    min-height: 36px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    line-height: 36px;
    text-transform: capitalize;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    text-align: center;
    letter-spacing: 0.025em;
    cursor: pointer;
    box-sizing: border-box;
    margin: 0;
    box-shadow: none;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    &.large {
      min-height: 56px;
      min-width: 120px;
      font-size: 18px;
    }

    &:hover {
      transition: all 0.2s ease-in-out;
      background-color: #007eb8 !important;
    }
  }

  &.btn-primary {
    background-color: $blue-bright;

    &.disabled,
    &:disabled {
      background-color: $gray-light;
      color: $white;
      opacity: 1;
      pointer-events: none;

      &:hover {
        background-color: $gray-light;
        color: $white;
        opacity: 1;
        pointer-events: none;
      }
    }
  }

  &-secondary {
    line-height: 32px;
  }

  &.btn-ghost {
    background-color: $transparent !important;
    color: $blue-bright;

    &:hover {
      background-color: $blue-light !important;
    }

    &.disabled {
      color: $gray-medium;
    }
  }

  &-group {
    .btn {
      & + .btn {
        margin-left: 8px;
      }
    }
  }
}

// page header
.page-header {
  padding-block: 20px;
  margin-inline: 20px;
  border-bottom: 1px solid $gray-light;

  h1 {
    font-weight: 700;
    font-size: 1rem;
    color: $gray-darkest;
    margin: 0 0 8px;
    white-space: nowrap;
    text-transform: uppercase;
  }

  .panel-heading {
    p {
      margin: 0;
      max-width: 80%;
      font-size: 13.6px;
    }
  }
}

// page body
.page-body {
  padding-block: 20px;
  margin-inline: 20px;

  .sub-header {
    h2 {
      font-weight: 600;
      font-size: 1rem;
      color: $gray-darkest;
      margin: 0;
    }

    p {
      margin: 4px 0 0;
      max-width: 80%;
      font-size: 13.6px;
    }
  }
}

// page footer
.page-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  border-top: 1px solid $gray-light;
  padding-inline: 24px;
  position: sticky;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgb(255 255 255 / 70%);
  backdrop-filter: blur(124px);
  z-index: 10;
}

// Footer
footer {
  color: $gray-lightest;
  background-color: $blue-darkest;
  min-height: 80px;
  font-size: 14px;
  width: 100%;
  position: absolute;
  bottom: 0;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
  }

  ul {
    margin: 0;
    padding: 0;
    text-align: center;
    line-height: normal;

    li {
      display: inline-block;
      padding: 0 10px;
      border-right: 1px solid $gray-lightest;
      line-height: 14px;

      &:last-child {
        border-right: none;
        padding-right: 0;
      }

      &:first-child {
        padding-left: 0;
      }

      #idx-iframe {
        /*-ms-transform: scale(0.85);
        -moz-transform: scale(0.85);
        -o-transform: scale(0.85);
        -webkit-transform: scale(0.85);
        transform: scale(0.85);

        -ms-transform-origin: 0 0;
        -moz-transform-origin: 0 0;
        -o-transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;*/
        border: none;
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 2;
      }
    }
  }

  p {
    margin: 0;
    line-height: normal;

    a.anchor-link {
      display: inline;
    }
  }

  .footer-logo {
    position: relative;

    span {
      position: absolute;
      font-size: 10px;
      color: $white;
      right: 2px;
      bottom: -10px;
    }

    .brand-logo {
      width: 150px;
      height: auto;
      max-height: 31px;
    }
  }
}

// login box
.login-box-wrap {
  display: flex;
  gap: 24px;

  .login-box {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 50%;

    .sub-header {
      margin-bottom: 16px;
    }

    .logo-blocks {
      display: flex;
      gap: 12px;

      .card {
        border: 1px solid $gray-light;
        padding: 10px 16px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        background-color: $blue-light;

        &:hover,
        &.active,
        &.selected {
          box-shadow: inset 0px 0px 0px 1px #008ecf;
          border-color: $blue-bright;
        }

        img {
          max-width: 220px;
          max-height: 48px;
        }

        &.card-upload > a {
          margin-left: 8px;
          font-weight: 500;
        }
      }
    }

    .login-box-colors {
      .form-control {
        margin-top: 12px;
        display: flex;
        gap: 12px;
        flex-wrap: wrap;

        span {
          margin-bottom: 4px;
          display: block;
        }
      }
    }

    .login-box-font {
      .form-control {
        margin-top: 12px;
      }
    }

    select {
      max-width: 300px;
    }
  }

  .iframe {
    width: 50%;

    iframe {
      border: none;
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 2;
      min-height: 546px;
      border: 0;
    }
  }
}

// 3 column
.grid-50 {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;

  & > div {
    width: calc((100% / 2) - 24px / 2);
  }
}

.card {
  &.light-gray {
    background-color: $gray-lightest-light;
    padding: 32px 24px;
    border-radius: 8px;
  }

  h3 {
    text-align: center;
    margin: 0 0 24px;
    font-size: 1rem;
    color: $gray-medium;
    font-weight: 500;
  }

  &.selected {
    box-shadow: inset 0px 0px 0px 2px $blue-bright;
    border-color: $blue-bright;
  }
}

// file button
.upload-btn-wrapper {
  position: relative;

  input[type="file"] {
    font-size: 0;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 120px;
    height: 36px;
    cursor: pointer;
  }
}

// tabs
.tabs {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  .tab {
    cursor: pointer;
    padding: 13px 24px;
    background: $gray-lightest-light;
    display: inline-block;
    color: $blue-bright;
    border-radius: 4px 4px 0px 0px;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    &:hover {
      background: $white;
      color: $blue-bright;
    }
  }
}

// panels
.panels {
  background: $white;
  min-height: 200px;
  width: 100%;
  border-radius: 3px;
  overflow: hidden;
  padding: 20px;
  border-radius: 0 0 4px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  .panels {
    padding: 20px 0;
  }
  .tabs {
    border-bottom: 1px solid $gray-light;
    .tab {
      background: transparent;
      padding: 0 16px 12px;
      color: $gray-dark;
    }
  }
  .panel {
    display: none;
    animation: fadein 0.8s;
    #one:checked ~ .tabs #one-tab,
    #two:checked ~ .tabs #two-tab,
    #three:checked ~ .tabs #three-tab,
    #four:checked ~ .tabs #four-tab,
    #five:checked ~ .tabs #five-tab,
    #six:checked ~ .tabs #six-tab,
    #seven:checked ~ .tabs #seven-tab,
    #eight:checked ~ .tabs #eight-tab,
    #nine:checked ~ .tabs #nine-tab,
    #ten:checked ~ .tabs #ten-tab {
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -1px;
        width: 100%;
        height: 2px;
        background: $blue-bright;
      }
    }
    .iframe {
      iframe {
        border: 0;
      }
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.radio {
  display: none;
}

#one:checked ~ .tabs #one-tab,
#two:checked ~ .tabs #two-tab,
#three:checked ~ .tabs #three-tab,
#four:checked ~ .tabs #four-tab,
#five:checked ~ .tabs #five-tab,
#six:checked ~ .tabs #six-tab,
#seven:checked ~ .tabs #seven-tab,
#eight:checked ~ .tabs #eight-tab,
#nine:checked ~ .tabs #nine-tab,
#ten:checked ~ .tabs #ten-tab,
#eleven:checked ~ .tabs #eleven-tab,
#twelve:checked ~ .tabs #twelve-tab,
#thirteen:checked ~ .tabs #thirteen-tab,
#fourteen:checked ~ .tabs #fourteen-tab {
  background: $white;
  color: $blue-bright;
  position: relative;
}

#one:checked ~ .panels #one-panel,
#two:checked ~ .panels #two-panel,
#three:checked ~ .panels #three-panel,
#four:checked ~ .panels #four-panel,
#five:checked ~ .panels #five-panel,
#six:checked ~ .panels #six-panel,
#seven:checked ~ .panels #seven-panel,
#eight:checked ~ .panels #eight-panel,
#nine:checked ~ .panels #nine-panel,
#ten:checked ~ .panels #ten-panel,
#eleven:checked ~ .panels #eleven-panel,
#twelve:checked ~ .panels #twelve-panel,
#thirteen:checked ~ .panels #thirteen-panel,
#fourteen:checked ~ .panels #fourteen-panel {
  display: block;
  width: 100%;
}

#customize_login:checked ~ .tabs #customize_login_one_tab,
#customize_email:checked ~ .tabs #customize_email_two_tab,
#customize_sms:checked ~ .tabs #customize_sms_three_tab {
  background: $white;
  color: $blue-bright;
}

#customize_login:checked ~ .panels #customize_login_one_panel,
#customize_email:checked ~ .panels #customize_email_two_panel,
#customize_sms:checked ~ .panels #customize_sms_three_panel {
  display: block;
  width: 100%;
}

.tab-warpper {
  background-color: $gray-lightest-light;
  padding: 24px;
  border-radius: 4px;
  .tab-warpper {
    background-color: transparent;
    padding: 0;
    border-radius: 0;
  }
}

// select list
select,
textarea {
  text-align: left;
  border: 1px solid $gray-medium;
  border-radius: 5px;
  padding: 8px 16px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: 10px 6px;
  background-position: calc(100% - 15px) 0.95rem;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5.99 3.19'%3E%3Cpath fill='%23030707' d='M3 3.19L5.99 0H0l3 3.19z'/%3E%3C/svg%3E");
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 100%;
  height: 40px;
  font-size: 0.85rem;
  color: $gray-darkest;
  background-color: $white;

  &:focus,
  &:focus-visible {
    box-shadow: inset 0px 0px 0px 1px $blue-bright;
    border-color: $blue-bright;
    outline-color: $blue-bright;
  }
}

.customization-wrapper {
  .accordion-warpper {
    // accordian
    $midnight: #fff;
    $clouds: #ecf0f1;
    // General

    input[type="radio"] {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }

    /* Accordion styles */
    .accordion-tabs {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 12px;

      .accordion-tab {
        border-radius: 4px;
        border: 1px solid $gray-medium;
        width: 100%;
        color: $gray-dark;
        overflow: hidden;

        &-label {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 12px 1em;
          background: $midnight;
          font-weight: 500;
          cursor: pointer;

          /* Icon */
          &:hover {
            background: $gray-lightest-light;
          }

          &::after {
            content: "";
            width: 1em;
            height: 1em;
            text-align: center;
            transition: all 0.35s;
            transform: rotate(-180deg);
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.360815 8.81173C0.545187 8.9915 0.79511 9.09248 1.05569 9.09248C1.31627 9.09248 1.56619 8.9915 1.75056 8.81173L7.6111 3.09013L13.4716 8.81173C13.658 8.9813 13.9046 9.07362 14.1593 9.06923C14.4141 9.06484 14.6571 8.96409 14.8373 8.7882C15.0174 8.61232 15.1206 8.37502 15.1251 8.12632C15.1296 7.87761 15.0351 7.63691 14.8614 7.45493L8.30597 1.05493C8.1216 0.875152 7.87168 0.774173 7.6111 0.774173C7.35052 0.774173 7.1006 0.875152 6.91622 1.05493L0.360815 7.45493C0.176673 7.63493 0.0732422 7.87893 0.0732422 8.13333C0.0732422 8.38773 0.176673 8.63173 0.360815 8.81173Z' fill='%2341484E'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: center;
          }
        }

        &-content {
          max-height: 0;
          padding: 0 1em;
          color: $gray-dark;
          background: $white;
          transition: all 0.35s;
          border-top: 1px solid $white;

          h3 {
            font-weight: 600;
            font-size: 0.875rem;
            color: $gray-darkest;
            margin: 0 0 12px;

            & ~ h3 {
              margin-top: 24px;
            }
          }
        }

        &-close {
          display: flex;
          justify-content: flex-end;
          padding: 1em;
          font-size: 0.75em;
          background: $white;
          cursor: pointer;

          &:hover {
            background: $gray-lightest-light;
          }
        }
      }
    }

    // :checked
    input:checked {
      + .accordion-tab-label {
        &::after {
          transform: rotate(0deg);
        }
      }

      ~ .accordion-tab-content {
        max-height: 100vh;
        padding: 1em;
        border-color: $gray-medium;
        overflow: auto;
      }
    }
  }

  .customization-preview {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border-style: solid;
    border-width: 1px 1px 1px 0;
    border-radius: 0 4px 4px 0;
    border-color: $gray-lightest;
    position: relative;
  }
  .iframe {
    width: 100%;
  }
}

.customization-wrapper {
  display: flex;
  position: relative;

  & > div {
    width: 50%;
    padding: 24px;
    border-radius: 4px 0 0 4px;
    &.desktop-active {
      width: 24px;
      padding-left: 0;
      & > div {
        display: none;
      }
    }
  }
  // toggle-btn`
  .toggle-btn {
    padding: 0;
    width: auto;
    z-index: 99999;
    button {
      width: 48px;
      cursor: pointer;
      background: none;
      border: 0;
      padding: 18px 0;
      margin-left: 2px;
      svg {
        -webkit-animation: pulse 2s infinite;
        animation: pulse 2s infinite;
        box-shadow: 0 0 0 rgba(0, 142, 207, 0.5);
        border-radius: 50%;
      }
    }
    &.mobile {
      position: absolute;
      top: 0;
      left: -26px;
    }
  }
  .accordion-warpper {
    background: $gray-lightest-light;
    border: 1px solid $gray-lightest;
  }

  .iframe {
    position: sticky;
    padding: 0;
  }
}

$lm-height: 2em;
$lm-anim-speed: 16s;
// $lm-anim-speed: 8s;

.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;

  .learnMore,
  .learnMore p {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  .learnMore {
    p {
      color: $gray-dark;
      height: $lm-height;
      font-size: $lm-height;
      line-height: $lm-height;

      strong {
        position: relative;
        overflow: hidden;
        height: $lm-height;
        font-weight: 400;

        &:hover a:link,
        &:hover a:visited {
          animation-play-state: paused;
        }

        span {
          text-align: center;
          display: block;
          color: $gray-dark;
          position: relative;
          top: 0;
          left: 0;
          text-decoration: none;
          transition: all 0.25s ease-in-out;
          padding: 0 1em;
          margin-left: 1em;
          animation: topToBottom $lm-anim-speed ease-in-out;
          animation-delay: 1s;
        }

        // a:link
      }

      // strong
    }

    // p
  }

  // .learnMore

  @keyframes topToBottom {
    0% {
      top: 0px;
    }

    10% {
      top: -$lm-height;
    }

    20% {
      top: -$lm-height * 2;
    }

    30% {
      top: -$lm-height * 3;
    }

    40% {
      top: -$lm-height * 4;
    }

    50% {
      top: -$lm-height * 4;
    }

    100% {
      top: -$lm-height * 4;
    }
  }

  .progress {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    height: 12px;
    overflow: hidden;
    background: $gray-lightest-light;
    border-radius: 16px;
    max-width: 768px;

    .bar {
      position: relative;
      float: left;
      min-width: 1%;
      height: 100%;
      background: $blue-bright;
      transition: all 0.2s ease-in-out;

      .percent {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
        font-size: 11px;
        color: $white;
        transition: all 0.2s ease-in-out;
      }
    }
  }
}

// actions button
.action-buttons {
  display: flex;
  gap: 8px;
  align-items: center;

  & > div {
    display: flex;
  }

  .btn {
    &-desktopview,
    &-mobileview {
      width: 46px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $blue-bright;
      background-color: $blue-light;
      cursor: pointer;

      svg {
        path {
          stroke: $gray-dark;
        }
      }

      &.active {
        background-color: $blue-bright;

        svg {
          path {
            stroke: $white;
          }
        }
      }
    }
    &-desktopview {
      border-radius: 4px 0 0 4px;
    }
    &-mobileview {
      border-radius: 0 4px 4px 0;
    }
  }
  .btn {
    &-left,
    &-center,
    &-right {
      width: 46px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $blue-bright;
      background-color: $blue-light;
      cursor: pointer;

      svg {
        path {
          stroke: $gray-dark;
        }
      }

      &.active {
        background-color: $blue-bright;

        svg {
          path {
            stroke: $white;
          }
        }
      }
    }
    &-left {
      border-radius: 4px 0 0 4px;
    }
    &-center {
      border-radius: 0;
      border-left: 0;
      border-right: 0;
    }
    &-right {
      border-radius: 0 4px 4px 0;
    }
  }
  &.logo-alignment {
    margin-top: 4px;
  }
}

// step2
#step2 {
  .page-body {
    .grid-50 {
      .card {
        &.light-gray {
          background-color: $gray-lightest-light;
          cursor: pointer;
          display: flex;
          flex-wrap: wrap;
          position: relative;
          flex-direction: column;
          transition: all 0.2s ease-in-out;
        }
        &:focus,
        &.selected {
          border-color: $blue-bright;
          box-shadow: inset 0px 0px 0px 2px $blue-bright;
        }

        .iframe {
          .cardTemplate {
            margin-inline: auto;
            border: none;
            position: relative;
            width: 100%;
            height: 100%;
            z-index: 2;
            min-height: 546px;
            overflow: hidden;
            border-radius: 8px;
          }
        }

        .cardSelected {
          z-index: 8;
          position: absolute;
          width: 100%;
          height: 100%;
          inset: 0;
          border-radius: 8px;
        }
      }
    }
  }
}

// customization-form
.customization-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.color {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $gray-medium;
  border-radius: 5px;
  text-align: left;
  padding: 0 4px 0 15px;
  box-sizing: border-box;
  height: 40px;
  font-size: 0.85rem;
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  color: $gray-darkest;
  background-color: $white;
  background-clip: padding-box;
  -webkit-transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  &:focus {
    background-color: $white;
    border-color: #80bdff;
    outline: 0;
    box-shadow:
      0 0 10px $blue-bright,
      0 0 4px $blue-bright;
  }

  input {
    width: 30px;
    height: 34px;
    padding: 0;
    margin: 0;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;

    ::-webkit-color-swatch-wrapper {
      padding: 0;
    }

    ::-webkit-color-swatch {
      border: 0;
      border-radius: 0;
    }

    ::-moz-color-swatch,
    ::-moz-focus-inner {
      border: 0;
    }

    ::-moz-focus-inner {
      padding: 0;
    }
  }
}
.iframe {
  iframe {
    border: 0;
  }
  &.desktopBrower {
    position: relative;
    border: 1px solid $gray-light;
    border-radius: 12px;
    padding-top: 32px;
    background-color: $white;
    &::before {
      width: 24px;
      height: 32px;
      content: "";
      position: absolute;
      top: 0;
      left: 16px;
      background: url("data:image/svg+xml,%3Csvg width='24' height='4' viewBox='0 0 24 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_740_7436)'%3E%3Cpath opacity='0.3' d='M2 4C3.10457 4 4 3.10457 4 2C4 0.89543 3.10457 0 2 0C0.89543 0 0 0.89543 0 2C0 3.10457 0.89543 4 2 4Z' fill='%23453A27'/%3E%3Cpath opacity='0.3' d='M12 4C13.1046 4 14 3.10457 14 2C14 0.89543 13.1046 0 12 0C10.8954 0 10 0.89543 10 2C10 3.10457 10.8954 4 12 4Z' fill='%23453A27'/%3E%3Cpath opacity='0.3' d='M22 4C23.1046 4 24 3.10457 24 2C24 0.89543 23.1046 0 22 0C20.8954 0 20 0.89543 20 2C20 3.10457 20.8954 4 22 4Z' fill='%23453A27'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_740_7436'%3E%3Crect width='24' height='4' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A")
        no-repeat center;
    }
    .cardTemplate {
      border-radius: 0 0 12px 12px !important;
      border-top: 1px solid #ecedee !important;
    }
  }
  &.mobileBrower {
    iframe {
      border-radius: 8px;
    }
    text-align: center;
    max-width: 441px;
    width: 100%;
    padding: 36px 12px 12px 12px;
    background-color: #ffffff;
    position: relative;
    border-radius: 12px;
    margin-inline: auto;
    border: 1px solid $gray-light;
    &::before {
      content: "";
      position: absolute;
      width: 40px;
      height: 4px;
      background: rgba(36, 28, 21, 0.3);
      border-radius: 4px;
      top: 12px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
}

.desktop {
  display: flex;
  flex-direction: column;
  & > div {
    width: 100%;
  }
  &.back-btn {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clipPath='url(%23clip0_813_10995)'%3E%3Cpath d='M0.75 10C0.75 15.1086 4.89137 19.25 10 19.25C15.1086 19.25 19.25 15.1086 19.25 10C19.25 4.89137 15.1086 0.75 10 0.75C4.89137 0.75 0.75 4.89137 0.75 10Z' fill='white' stroke='%23008ECF' strokeWidth='1.5'/%3E%3Cpath d='M7.41725 15.4477L6.9224 14.9528C6.86614 14.8965 6.83453 14.8201 6.83453 14.7405C6.83453 14.6609 6.86614 14.5845 6.9224 14.5282L11.4399 10L6.9224 5.47186C6.86614 5.41553 6.83453 5.33917 6.83453 5.25955C6.83453 5.17994 6.86614 5.10358 6.9224 5.04725L7.41725 4.55241C7.47357 4.49614 7.54993 4.46454 7.62955 4.46454C7.70916 4.46454 7.78552 4.49614 7.84185 4.55241L13.0772 9.78773C13.1334 9.84406 13.165 9.92042 13.165 10C13.165 10.0796 13.1334 10.156 13.0772 10.2123L7.84185 15.4477C7.78552 15.5039 7.70916 15.5355 7.62955 15.5355C7.54993 15.5355 7.47357 15.5039 7.41725 15.4477Z' fill='%23008ECF'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_813_10995'%3E%3Crect width='20' height='20' fill='white' transform='matrix(-1 0 0 1 20 0)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    outline: none;
    border: none;
    background-color: transparent;
  }
}
.align-right {
  display: flex;
  justify-content: flex-end;
}
textarea {
  resize: vertical;
  min-height: 120px;
  background-image: none;
}
@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 142, 207, 0.5);
  }
  70% {
    box-shadow: 0 0 0 12px rgba(0, 142, 207, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 142, 207, 0);
  }
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 142, 207, 0.5);
  }
  70% {
    box-shadow: 0 0 0 12px rgba(0, 142, 207, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 142, 207, 0);
  }
}

:root {
  iframe#webpack-dev-server-client-overlay {
    display: none !important;
  }
}
